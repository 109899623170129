import { Text, useModalState } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { Button } from "@src/appV2/redesign/components/Button";

import { LearnMoreSheet } from "./LearnMoreSheet";

interface OnCallLearnMoreProps {
  workplaceId?: string;
}

export function OnCallLearnMore(props: OnCallLearnMoreProps) {
  const { workplaceId } = props;

  const modalState = useModalState();

  return (
    <Stack
      alignItems="center"
      justifyContent="space-between"
      direction="row"
      px={6}
      py={4}
      sx={(theme) => ({
        borderRadius: theme.borderRadius?.small ?? "inherit",
        border: "2px solid transparent",
        background:
          "linear-gradient(white, white) padding-box, linear-gradient(110.28deg, #F5DCB9 7%, #8CCBEC 92%) border-box",
      })}
    >
      <Text>This is an on-call shift</Text>
      <Button variant="outlined" size="small" onClick={modalState.openModal}>
        Learn more
      </Button>
      <LearnMoreSheet modalState={modalState} workplaceId={workplaceId} />
    </Stack>
  );
}
