import { CardWithIntent, type CardWithIntentProps } from "@clipboard-health/ui-components";
import { mergeSxProps } from "@clipboard-health/ui-react";
import { type IntentVariant } from "@clipboard-health/ui-theme";
import type { Ref } from "react";
import { forwardRef } from "react";

export type ShiftBottomSheetInnerCardIntent = IntentVariant | "priority";

interface ShiftBottomSheetInnerCardProps extends Omit<CardWithIntentProps, "intent"> {
  intent?: ShiftBottomSheetInnerCardIntent;
}

function BaseShiftBottomSheetInnerCard(
  props: ShiftBottomSheetInnerCardProps,
  ref: Ref<HTMLDivElement>
) {
  const { sx, intent = "neutral", ...restProps } = props;

  return (
    <CardWithIntent
      {...restProps}
      ref={ref}
      intent={intent === "priority" ? undefined : intent}
      sx={mergeSxProps(
        (theme) => ({
          minHeight: "20rem",
          border:
            intent === "priority" ? `1px solid ${theme.palette.shift?.priority.border}` : undefined,
        }),
        sx
      )}
    />
  );
}

export const ShiftBottomSheetInnerCard = forwardRef(BaseShiftBottomSheetInnerCard);
