import { BottomSheet, CbhIcon } from "@clipboard-health/ui-components";
import { Text, Title, type UseModalState } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { Button } from "@src/appV2/redesign/components/Button";
import { useOnCallShiftsConfig } from "@src/appV2/Shifts/Shift/useOnCallShiftsConfig";

import { OnCallBottomSheetCloseButton } from "./OnCallBottomSheetCloseButton";

interface LearnMoreSheetProps {
  modalState: UseModalState;
  workplaceId?: string;
}

export function LearnMoreSheet(props: LearnMoreSheetProps) {
  const { modalState, workplaceId } = props;

  const { noPlacementBonusPayHours: globalNoPlacementBonusPayHours, onCallAreas } =
    useOnCallShiftsConfig();
  const workplaceConfig = onCallAreas.find((area) => area.parentWorkplaceId === workplaceId);
  const noPlacementBonusPayHours =
    workplaceConfig?.noPlacementBonusPayHours ?? globalNoPlacementBonusPayHours;

  return (
    <BottomSheet modalState={modalState}>
      <Stack
        spacing={10}
        sx={{ backgroundColor: (theme) => theme.palette.background.tertiary }}
        py={8}
        px={10}
      >
        <OnCallBottomSheetCloseButton onClick={modalState.closeModal} />
        <Stack alignItems="center" justifyContent="center" textAlign="center" spacing={5}>
          <Title component="h4" py={3}>
            What to expect when you&apos;re on call for a shift
          </Title>
          <Text variant="body2">
            Wait at home while your on-call period is going on. When you book on-call, you lock in
            the pay rate you booked it at for your shift placement.
          </Text>
          <Text variant="body2">
            We’ll give you placements during your on-call period, you’ll have 30 minutes to pick 1
            of them! Your placement contains important information about the shift and facility.
          </Text>
          <Text variant="body2">
            If we don’t find you a placement, you get {noPlacementBonusPayHours} hours of pay...
            just for staying home!
          </Text>
          <Text variant="body2">If you have questions, please call us.</Text>
        </Stack>
        <Button variant="outlined" size="large" href="tel:+19732405483">
          <Stack direction="row" alignItems="center" spacing={3}>
            <CbhIcon type="phone" />
            <Text>Call support</Text>
          </Stack>
        </Button>
      </Stack>
    </BottomSheet>
  );
}
