import { logEvent } from "@src/appV2/lib/analytics";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib/analytics/events";
import { useMarkInterestShift } from "@src/appV2/OpenShifts/ShiftAction/api/useMarkInterestShift";
import { useQueryClient } from "@tanstack/react-query";

import { LoadingButton } from "../../components/LoadingButton";
import { invalidateShifts } from "../../invalidateShifts";

interface MarkInterestButtonProps {
  shiftId: string;
}

export function MarkInterestButton(props: MarkInterestButtonProps) {
  const { shiftId } = props;

  const queryClient = useQueryClient();

  const { mutate: markInterest, isLoading: isMarkingInterest } = useMarkInterestShift({
    onSuccess: async () => {
      logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_CTA_MARK_INTEREST, {
        shiftId,
      });

      await invalidateShifts(queryClient);
    },
  });

  return (
    <LoadingButton
      size="large"
      variant="contained"
      isLoading={isMarkingInterest}
      onClick={() => {
        markInterest({ shift: shiftId });
      }}
    >
      Request approval
    </LoadingButton>
  );
}
