import { Box, type BoxProps } from "@mui/material";
import { IconButton } from "@src/appV2/redesign/components/IconButton";

interface OnCallBottomSheetCloseButtonProps extends BoxProps {
  onClick?: () => void;
}

export function OnCallBottomSheetCloseButton(props: OnCallBottomSheetCloseButtonProps) {
  const { onClick, ...rest } = props;

  return (
    <Box
      position="absolute"
      top={(theme) => theme.spacing(6)}
      right={(theme) => theme.spacing(6)}
      sx={rest}
    >
      <IconButton size="small" iconType="close" variant="outlined" onClick={onClick} />
    </Box>
  );
}
