import { ShiftBottomSheetBookabilityCriteriaCard } from "./BookabilityCriteriaCard";

export function ShiftBottomSheetMarkedAsInterestedCard() {
  return (
    <ShiftBottomSheetBookabilityCriteriaCard
      title="Request sent"
      description="If you are approved, a representative from Clipboard will reach out."
      illustrationType="request-sent"
    />
  );
}
