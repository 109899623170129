import { CbhIcon, Divider, TextSkeleton } from "@clipboard-health/ui-components";
import { Box, Stack } from "@mui/material";

export function OnCallShiftBottomSheetSkeleton() {
  return (
    <Box sx={{ backgroundColor: (theme) => theme.palette.background.tertiary }}>
      <Stack px={5}>
        <Stack py={7} spacing={5}>
          <Stack direction="row" spacing={5} justifyContent="flex-start">
            <CbhIcon type="old-phone" />
            <TextSkeleton width="20rem" />
          </Stack>
          <Divider />
          <Stack direction="row" spacing={5} justifyContent="flex-start">
            <CbhIcon type="car-filled" />
            <TextSkeleton width="20rem" />
          </Stack>
          <Divider />
          <Stack direction="row" spacing={5} justifyContent="flex-start">
            <CbhIcon type="map-pin" />
            <TextSkeleton width="20rem" />
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
