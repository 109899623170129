import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextSkeleton,
} from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Stack } from "@mui/material";
import { type BookabilityStatusItem } from "@src/appV2/OpenShifts/ShiftAction";
import { useShiftModalsDataContext } from "@src/appV2/redesign/ShiftDiscovery/useShiftModalsDataContext";
import { getFormattedTimeRangeWithTimezone } from "@src/appV2/redesign/utils/getFormattedTimeRangeWithTimezone";
import { addHours, isAfter, parseISO } from "date-fns";

import { type ShiftType } from "../../constants";
import { getTimeSlotLabel } from "../../getTimeSlotLabel";
import { type ModalWorkplaceData } from "../../types";
import {
  ShiftBottomSheetBookabilityCard,
  ShiftBottomSheetBookabilityCardsContainer,
} from "../BookabilityCardsContainer";

interface ShiftBottomSheetSheetContentProps {
  shiftId: string;
  shiftType: ShiftType;
  qualificationName: string;
  workplace: ModalWorkplaceData;
  bookabilityStatus: BookabilityStatusItem;
}

export function OnCallShiftBottomSheetContent(props: ShiftBottomSheetSheetContentProps) {
  const { shiftId, shiftType, qualificationName, workplace, bookabilityStatus } = props;

  const {
    shift,
    isLoadingShiftModalsData,
    hasHoursRestrictionConflict,
    shiftFailedLoading,
    hasMarkedInterest,
    hasBlockingRequirements,
  } = useShiftModalsDataContext();

  const { location } = workplace.attributes;

  // we want to trim on-call shifts so we only show the first three hours at most
  const startDate = parseISO(shift?.attributes.start ?? "");
  const endDate = parseISO(shift?.attributes.end ?? "");
  const maxEndDateToShow = addHours(startDate, 3);
  const { label } = getFormattedTimeRangeWithTimezone({
    dateRange: {
      startDate,
      endDate: isAfter(endDate, maxEndDateToShow) ? maxEndDateToShow : endDate,
    },
    timezone: location.timezone,
    deviceTimezone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  return (
    <Box sx={{ backgroundColor: (theme) => theme.palette.background.tertiary, overflowY: "auto" }}>
      <Stack px={5}>
        <ShiftBottomSheetBookabilityCardsContainer
          shiftId={shiftId}
          shiftType={shiftType}
          workplace={workplace}
          bookabilityStatus={bookabilityStatus}
          hasHoursRestrictionConflict={hasHoursRestrictionConflict}
          shiftFailedLoading={shiftFailedLoading}
          hasMarkedInterest={hasMarkedInterest}
          hasBlockingRequirements={hasBlockingRequirements}
          cardsToSkip={[ShiftBottomSheetBookabilityCard.URGENT]}
        />

        <List>
          <ListItem>
            <ListItemIcon iconType="old-phone" />
            <ListItemText>
              <Stack spacing={2}>
                {isDefined(shift) && !isLoadingShiftModalsData ? (
                  <Text variant="h6">On call from {label}</Text>
                ) : (
                  <TextSkeleton width="60%" />
                )}
                <Text variant="caption">Wait for your assignment</Text>
              </Stack>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemIcon iconType="car-filled" />
            <ListItemText>
              <Stack spacing={2}>
                {isDefined(shift) && !isLoadingShiftModalsData ? (
                  <Text variant="h6">{getTimeSlotLabel(shift?.attributes.timeSlot)} shift</Text>
                ) : (
                  <TextSkeleton width="60%" />
                )}
                <Text variant="caption">Approx. 8 hours — TBD</Text>
              </Stack>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemIcon iconType="map-pin" />
            <ListItemText>
              <Stack spacing={2}>
                <Text variant="h6">Workplace TBD</Text>
                <Text variant="caption">{qualificationName} shift</Text>
              </Stack>
            </ListItemText>
          </ListItem>
        </List>
      </Stack>
    </Box>
  );
}
