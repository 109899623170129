import { CbhIcon } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

export function InstantPayIndicator() {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <CbhIcon type="bolt-filled" color={(theme) => theme.palette.instantPay?.text} size="small" />
      <Text color={(theme) => theme.palette.instantPay?.text}>InstantPay</Text>
    </Stack>
  );
}
