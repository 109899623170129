import { formatShortDateWithWeekday } from "@clipboard-health/date-time";
import { BottomSheet } from "@clipboard-health/ui-components";
import { Title, type UseModalState } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { parseISO } from "date-fns";

import { ShiftCardWrapper } from "../../Card/Wrapper";
import { OnCallShiftCardContent } from "../../Open/OnCall/CardContent";
import { type ModalShiftData, type ModalWorkplaceData } from "../../types";
import { ConfirmRegisterOnCallShiftButton } from "./ConfirmRegisterOnCallShiftButton";
import { OnCallBottomSheetCloseButton } from "./OnCallBottomSheetCloseButton";

interface ConfirmRegisterOnCallShiftSheetProps {
  modalState: UseModalState;
  shift: ModalShiftData;
  workplace: ModalWorkplaceData;
  onConfirm: () => void;
}

export function ConfirmRegisterOnCallShiftSheet(props: ConfirmRegisterOnCallShiftSheetProps) {
  const { modalState, shift, workplace, onConfirm } = props;

  const startDate = parseISO(shift?.attributes.start ?? "");
  const endDate = parseISO(shift?.attributes.end ?? "");
  const { timezone } = workplace.attributes.location;
  const deviceTimezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <BottomSheet modalState={modalState}>
      <Stack
        spacing={10}
        sx={{ backgroundColor: (theme) => theme.palette.background.tertiary }}
        py={8}
        px={5}
      >
        <OnCallBottomSheetCloseButton onClick={modalState.closeModal} />
        <Stack alignItems="center" justifyContent="center" spacing={8}>
          <Title component="h3" py={3} px={4} sx={{ textWrap: "balance" }} textAlign="center">
            Confirm to be on call{" "}
            {formatShortDateWithWeekday(shift.attributes.start, {
              timeZone: timezone,
            })
              .split(",")
              .slice(1)}
          </Title>
          <ShiftCardWrapper sx={{ width: "100%" }}>
            <OnCallShiftCardContent
              timeSlot={shift.attributes.timeSlot}
              startDate={startDate}
              endDate={endDate}
              qualificationText={shift.attributes.qualification}
              workplaceTimezone={timezone}
              deviceTimezone={deviceTimezone}
              hourlyPay={shift.offer?.attributes.formattedHourlyPay}
            />
          </ShiftCardWrapper>
        </Stack>
        <ConfirmRegisterOnCallShiftButton
          shiftId={shift.id}
          shiftOfferId={shift.offer?.id}
          workplaceId={workplace.id}
          onBook={onConfirm}
        />
      </Stack>
    </BottomSheet>
  );
}
