import { formatShortDateWithWeekday } from "@clipboard-health/date-time";
import { PillSkeleton, TextSkeleton } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { APP_V2_USER_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { PageHeaderTopRow } from "@src/appV2/redesign/components/PageHeaderTopRow";
import { useShiftModalsDataContext } from "@src/appV2/redesign/ShiftDiscovery/useShiftModalsDataContext";

import { getGradientByTimeSlot } from "../../getGradientByTimeSlot";

interface OnCallShiftBottomSheetHeaderProps {
  onClose: () => void;
}
export function OnCallShiftBottomSheetHeader(props: OnCallShiftBottomSheetHeaderProps) {
  const { onClose } = props;
  const { shift, isLoadingShiftModalsData, shiftFailedLoading } = useShiftModalsDataContext();

  return (
    <Stack
      alignItems="space-between"
      sx={(theme) => ({
        paddingTop: 6,
        paddingBottom: 7,
        px: 5,
        transition: theme.transitions.create(["background"]),
        background: isDefined(shift?.attributes.timeSlot)
          ? getGradientByTimeSlot({ timeSlot: shift.attributes.timeSlot, theme })
          : undefined,
      })}
      spacing={4}
    >
      <Stack spacing={6}>
        <PageHeaderTopRow
          sx={{ px: 0, paddingBottom: 3 }}
          onClickBack={() => {
            logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_CLOSED, { shiftId: shift?.id ?? "" });

            if (shiftFailedLoading) {
              logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_UNAVAILABLE_CLOSED, {
                shiftId: shift?.id ?? "",
              });
            }

            onClose();
          }}
        />

        <Stack spacing={4}>
          {!isDefined(shift) && isLoadingShiftModalsData ? (
            <>
              <TextSkeleton width="30%" />
              <PillSkeleton />
            </>
          ) : (
            <>
              <Text>{shift?.attributes.qualification} · On call</Text>
              <Text variant="h3">
                {formatShortDateWithWeekday(shift?.attributes.start ?? "", {
                  timeZone: shift?.workplace.attributes.location.timezone,
                })}
              </Text>
            </>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
