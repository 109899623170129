import { TextSkeleton } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";

import { InstantPayIndicator } from "./InstantPayIndicator";

interface OnCallShiftPayIndicatorProps {
  totalPay?: string;
  hourlyPay?: string;
  isInstantPay?: boolean;
  isLoading?: boolean;
}

export function OnCallShiftPayIndicator(props: OnCallShiftPayIndicatorProps) {
  const { totalPay, hourlyPay, isInstantPay, isLoading } = props;

  return (
    <Stack spacing={3}>
      {isLoading ? (
        <>
          <TextSkeleton width="50%" />
          <TextSkeleton width="40%" />
        </>
      ) : (
        <>
          <Stack direction="row" alignItems="baseline" spacing={1}>
            <Text variant="h4">{hourlyPay}</Text>
            <Text variant="body1"> /hr</Text>
          </Stack>
          <Stack direction="row" spacing={3} alignItems="center">
            <Text>~{totalPay}</Text>
            {isInstantPay && <InstantPayIndicator />}
          </Stack>
        </>
      )}
    </Stack>
  );
}
